.LangContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem;

    grid-area: lang;
    cursor: pointer;
}

.LangSwitcherbtn{
    width: auto;
    height: 1.2rem;
    margin: 5px;
    cursor: pointer;
    justify-self: start;
}