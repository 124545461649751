.containerSuitesView{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    width: 100%;
    align-items: start;

    row-gap: 20px;
    column-gap: 20px;

    padding-top: 20px;
    padding-bottom: 20px;
    
} 

@media (max-width: 1150px) {
    .containerSuitesView{
        grid-template-columns: 1fr 1fr;
    }   
}
@media (max-width: 600px) {
    .containerSuitesView{
        grid-template-columns: 1fr;
    }   
}
