@font-face {
    font-family: 'Century Gothic';
    src: url('./fonts/GOTHICB.TTF') format('truetype');
  }

  @font-face {
    font-family: 'Century Gothic Italic';
    src: url('./fonts/GOTHICI.TTF') format('truetype');
  }
  
  body {
    font-family: 'Century Gothic', Arial, sans-serif;
  }

  .flex{
    display: flex;
    align-items: center;
  }
  @media (max-width: 900px) {
  .hidebar{
    display: none;
  }
}  