.imageGalery{
    width: 100%;
    margin: auto;
    overflow: hidden;
}

.imageGalery > *{
    width: 100%;
    max-height: 74vh;
    object-fit: fill;
}