.amenitieimg{
    height: 4rem;
    width: auto;
}
.amenitiesText{
    justify-self: left;
    padding-left: 5%;
    padding-right: 2%;
    top: 1%;
    background-color:#244971;
    font-size: 1.5rem !important;
}

@media (max-width: 600px) {

    .amenitiesText{
        font-size: 1.2rem !important;
    }
    
  }  