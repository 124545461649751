.containerExploreOurSuitesSection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
        "principal principal principal"
        "foto1 foto2 foto3"
        "tittle tittle tittle"
        "texto texto texto"
        "Explorebtn Explorebtn Explorebtn";

    align-items: center;
    column-gap: 5px;
    row-gap: 5px;
    padding: 8px;
} 

.imgMainRooms{
    width: 100%;
    height: auto;
}

.principal{
    grid-area: principal;
}

.foto1{
    grid-area: foto1;
}

.foto2{
    grid-area: foto2;

}

.foto3{
    grid-area: foto3;

}
.foto4{
    display: none;
    grid-area: foto4;

}

.Exploretittle{
    grid-area: tittle;
}

.textos{
    padding: 10px;
    text-align: left;
    grid-area: texto;
    min-height: 210px; 
    
}

.Explorebtncontainer{
    grid-area: Explorebtn;
}

.Mainbtn{

    height: 3rem;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: #244971;
    border: 1px solid white;
    border-radius: 10px;

}

.Mainbtn:hover {
    background-color: #173555;
    cursor: pointer;
}


@media (max-width: 1150px) {

    .containerExploreOurSuitesSection{
        grid-template-columns: minmax(150px, 200px) minmax(400px, 550px);
        grid-template-areas: 
            "foto tittle"
            "foto texto"
            "foto texto"
            "foto Explorebtn";
            align-items: start;
            padding: 20px;    
    }
    .Exploretittle{
        grid-area: tittle;
    }
    .textos{
        padding-left: 2rem;
        text-align: left;
        margin-top: -60px;
        grid-area: texto;
        min-height: auto; 
    }
    .principal{
        grid-area: foto;
        grid-row: 1;
        
    }
    
    .foto1{
        grid-area: foto;
        grid-row: 2;

    }
    
    .foto2{
        grid-area: foto;
        grid-row: 3;

    
    }
    .foto3{
        display: none;
    }
    .Explorebtncontainer{
        align-self: center;
        grid-area: Explorebtn;
        grid-row: 3;
    }
}
@media (max-width: 600px) {

    .containerExploreOurSuitesSection{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: 
            "tittle tittle tittle tittle"
            "principal principal principal principal"
            "foto1 foto2 foto3 foto4"
            "texto texto texto texto"
            "Explorebtn Explorebtn Explorebtn Explorebtn";

            padding: 20px;    
    }
    .foto4{
        display: block;
    }
    
    .imgMainRooms{
        width: 100%;
        height: auto;
    }
    
    .principal{
        grid-area: principal;
    }
    
    .foto1{
        grid-area: foto1;
    }
    
    .foto2{
        grid-area: foto2;
    
    }
    
    .foto3{
        grid-area: foto3;
        display: block;
    }
    
    .Exploretittle{
        grid-area: tittle;
    }
    
    .textos{
        padding-left: 0;
        margin-top: 0;
        grid-area: texto;
        min-height: auto; 
        
    }
    
    .Explorebtncontainer{
        grid-area: Explorebtn;
    }
    

}