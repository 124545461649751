.containerMainRoom{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    width: 100%;
    align-items: center;
    justify-items: center;

    column-gap: 8px;
    padding-bottom: 2rem;

    align-items: start;
} 

@media (max-width: 1150px) {

    .containerMainRoom{
        grid-template-columns: 1fr;
        row-gap: 10px;
    }

}