.containerSuitesViewSection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
        "principalsvs principalsvs principalsvs"
        "foto1svs foto2svs foto3svs"
        "textosvs textosvs textosvs"
        "char1svs char1svs char1svs"
        "char2svs char2svs char2svs"
        "char3svs char3svs char3svs"
        "char4svs char4svs char4svs"
        "char5svs char5svs char5svs"
        "btnsvs btnsvs btnsvs";

    column-gap: 4px; 

    align-items: start;
    padding: 15px;     
    
} 

.imgSuitesViewSection{
    width: 100%;
    height: auto;
}

.imgChar{
    width: auto;
    height: 1rem;
    padding-right: 5px;
}

.principalSuitesViewSection{
    grid-area: principalsvs;
}

.foto1SuitesViewSection{
    grid-area: foto1svs;
}

.foto2SuitesViewSection{
    grid-area: foto2svs;

}

.foto3SuitesViewSection{
    grid-area: foto3svs;

}

.imgGallery{
    display: none;
}


.textosSuitesViewSection{
    grid-area: textosvs;
    min-height: 115px;
    margin-bottom: 1rem;

}

.char1SuitesViewSection{
    width: 100%;
    grid-area: char1svs;
    min-height: 1.5rem;
    
}
.char2SuitesViewSection{
    width: 100%;
    grid-area: char2svs;
    min-height: 1.5rem;
    
}
.char3SuitesViewSection{
    width: 100%;
    grid-area: char3svs;
    min-height: 1.5rem;
}

.char4SuitesViewSection{
    width: 100%;
    grid-area: char4svs;
    min-height: 1.5rem;
}

.char5SuitesViewSection{
    width: 100%;
    grid-area: char5svs;
    min-height: 1.5rem;
}

.Mainbtncontainer{
    grid-area: btnsvs;
}

.Mainbtn{

    height: 3rem;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: #244971;
    border: 1px solid white;
    border-radius: 10px;

}

.Mainbtn:hover {
    background-color: #173555;
    cursor: pointer;
}

@media (max-width: 1150px) {

    .containerSuitesViewSection{
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 
            "imgGallery imgGallery imgGallery"
            "textosvs textosvs textosvs"
            "char1svs char1svs char1svs"
            "char2svs char2svs char2svs"
            "char3svs char3svs char3svs"
            "char4svs char4svs char4svs"
            "char5svs char5svs char5svs"
            "btnsvs btnsvs btnsvs";
     
    } 
    .imgGallery{
        grid-area: imgGallery ;
        display: block;
        
    }
    .textosSuitesViewSection{
        min-height: auto;
        padding-bottom: 1.5rem;
    }

    .principalSuitesViewSection{
        display: none;
    }
    
    .foto1SuitesViewSection{
        display: none;
    }
    
    .foto2SuitesViewSection{
        display: none;
    
    }
    
    .foto3SuitesViewSection{
        display: none;
    
    }
    .char1SuitesViewSection{
        padding-left: 10px;
        
    }
    .char2SuitesViewSection{
        padding-left: 10px;
        
    }
    .char3SuitesViewSection{
        padding-left: 10px;
        
        
    }
    .char4SuitesViewSection{
        padding-left: 10px;
        
        
    }
    .char5SuitesViewSection{
        padding-left: 10px;
        
        
    }
    

}