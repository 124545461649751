.containerFullSuitesView{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "tittle tittle"
        "video text"
        "video btn";
    align-items: center;
    justify-items: start;
    column-gap: 5px;

    margin-bottom: 5rem;
} 


.video{
    grid-area: video;
    width: 100%;
}

.fullviewtittle{
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    grid-area: tittle;
}

.fullviewtextos{
    align-self: self-start;
    grid-area: text;
    padding-top: 2rem;
    padding-left: 15px;
}

.fullviewbtn{
    grid-area: btn;
    justify-self: end;
    margin-bottom: 4rem;
    width: 50%;
}
.fullViewMainbtn{
    height: 3rem;
    padding: 20px;
    padding-left: 5rem;
    padding-right: 5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: #244971;
    border: 1px solid white;
    border-radius: 10px;
}

.fullViewMainbtn:hover {
    background-color: #173555;
    cursor: pointer;
}

.imgGalleryFullInfoSuites{
    display: none;
}

.containerFullSuitesFotos{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    column-gap: 5px;
    row-gap: 5px;
    margin-bottom: 5rem;

} 

.fullviewimg{
    width: 100%;
    height: auto;
}

.containerFullSuitesChar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: start;
    column-gap: 5px;
    row-gap: 5px;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 1rem;

   background-color: #202020;


} 

.containerFullSuitesChar2{
    display: none;
}


.charitem{
    padding: 10px;
    padding-left: 5rem;
}

.fullimgChar{
    height: 2rem;
    padding-right: 20px;
}

@media (max-width: 1150px) {

    .containerFullSuitesView{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "tittle tittle"
            "video video"
            "btn btn"
            "text text";
        align-items: center;
        justify-items: start;
        column-gap: 5px;
    
        margin-bottom: 5rem;
    } 

    .video{
        width: 95%;
        justify-self: center;
    }

    .fullviewbtn{
        margin-bottom: 2rem;
        width: 100%;
        justify-content: center;
        
    }

    .containerFullSuitesFotos{
        display: none;
    
    } 
    .containerFullSuitesChar{
        display: none;
    }

    .imgGalleryFullInfoSuites{
        display: block;
    }

    .containerFullSuitesChar2{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "charitem charitem"
            "charitem charitem"
            "charitem charitem"
            "chars chars";
            column-gap: 5px;
            row-gap: 5px;
            padding-top: 4rem;
            padding-bottom: 4rem;
            margin-bottom: 1rem;
           background-color: #202020;
    }
    .containerFullSuitesChar2flex{
        grid-area: chars;
        display: flex;
        justify-content: space-around;
        
    }
    .charitem{
        padding: 0;
        padding-left: 0;

        padding-bottom: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fullimgChar{

        padding-right: 5px;
        width: 2rem;
        height: auto;
    }
    

}

@media (max-width: 600px) {

    .containerFullSuitesView{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "tittle tittle"
            "video video"
            "btn btn"
            "text text";
        align-items: center;
        justify-items: start;
        column-gap: 5px;
    
        margin-bottom: 5rem;
    } 
    .fullviewbtn{
        margin-bottom: 2rem;
        width: 100%;
        justify-content: center;
        
    }

    .containerFullSuitesFotos{
        grid-template-columns: 1fr;
        display: block;
    
    } 
    
    .imgGalleryFullInfoSuites{
        display: none;
    }
    .containerFullSuitesChar{
        display: none;
    }

    .containerFullSuitesChar2{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "charitem charitem"
            "charitem charitem"
            "charitem charitem"
            "chars chars";
            column-gap: 5px;
            row-gap: 5px;
            padding-top: 4rem;
            padding-bottom: 4rem;
            margin-bottom: 1rem;
           background-color: #202020;
    }
    .containerFullSuitesChar2flex{
        grid-area: chars;
        display: flex;
        justify-content: space-around;
        
    }
    .charitem{
        padding: 0;
        padding-left: 0;

        padding-bottom: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fullimgChar{

        padding-right: 5px;
        width: 2rem;
        height: auto;
    }
    

}




