
.footcontainer{

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "name name"
    "tel mail"
    "facebook instagram";
    
    row-gap: 30px;
    column-gap: 50px;

}

.name{
    grid-area: name;
}
.tel{
    justify-self: end;
    grid-area: tel;
}
.mail{
    grid-area: mail;
}
.facebook{
    justify-self: end;
    grid-area: facebook;
}
.instagram{
    grid-area: instagram;
}

.center{
    display: flex;
    align-items: center;
}
.icons{
    margin-right: 5px;
    height: 1.2rem;
    width: 1.2rem;
}

@media (max-width: 900px) {
    
    .footcontainer{
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "name name"
        "tel tel"
        "mail mail"
        "facebook instagram";
    }
    .tel{
        justify-self: center;
        
    }
    .mail{
        justify-self: center;
    }
}