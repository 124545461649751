.navcontainer{

    display: grid;
    grid-template-columns: 160px 150px auto auto auto auto auto 3.2rem;
    grid-template-areas: 
        "logo tripAdvisor text1 text2 text3 text4 btn lang" ;
    
    align-items: center;

}

.navbarMenu{
    display: none;
    grid-area: navMenu;
}

.menuicon{
    height: 35px;
    width: auto;
}

.navimg{
    height: 63px;
    width: auto;
    border-radius: 5px;
    
}

.navlogo{
    grid-area: logo;
    
}
.tripAdvisor{
    grid-area: tripAdvisor;

    overflow: hidden;
    object-fit: fill;
}

.navtext1{
    grid-area: text1; 
}

.navtext2{
    grid-area: text2;
}

.navtext3{
    grid-area: text3;
}

.navtext4{
    grid-area: text4;
}

.navbtn{
    grid-area: btn;
    height: 75%;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: #19334F;
    border: 1px solid white;
    border-radius: 10px;
    
}

.navbtn:hover {
    background-color: #173555;
    cursor: pointer;
}

.navstyled-btn{
    height: 75%;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    
}

.navstyled-btn:hover{
    background-color: #173555;
    cursor: pointer;
}

@media (max-width: 1150px) {
    .navcontainer{
        display: grid;
        grid-template-columns: .5fr 1fr 3fr auto 3.2rem;
        grid-template-areas: 
            "navMenu tripAdvisor logo btn lang" ;
        
        align-items: center;
        justify-items: center;
    }
    .navbarMenu{
        display: block ;
        justify-self: start;
        padding-left: 1rem;
    }
    .navstyled-btn{
        display:none ;
    }
    
    .tripAdvisor{
        justify-self: start;
 
    }
}

@media (max-width: 600px) {
    .navcontainer{
        display: grid;
        grid-template-columns: 1fr 1fr auto 3.2rem;
        grid-template-areas: 
            "navMenu logo tripAdvisor lang" ;
        
        align-items: center;
        justify-items: center;
    }
    .navbarMenu{
        display: block ;
        justify-self: start;
        padding-left: 1rem;
    }
    .navstyled-btn, .navbtn{
        display:none ;
    }
    .tripAdvisor{
        align-self: center;
        justify-self: end;
        margin-right: 1rem;
    }
}