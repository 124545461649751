.nearPlaces{

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    width: 90%;
    margin-top: 3rem;
    row-gap: 1rem;
    column-gap: 1rem;
    max-width: 900px;
}
.nearPlace{
    min-width: 200px;
}
.locationimg{
    height: 3rem;
    width: auto;
}
.locationPageTable{
    width: 50% !important;
    margin-bottom: 20px ;
}

@media (max-width: 1150px){
    .locationPageTable{
        width: 70% !important;
  
    }
}
@media (max-width: 600px) {
    .locationPageTable{
        width: 90% !important;
  
    }
    .nearPlace{
        min-width: 150px;

    }

}